/* eslint-disable no-console,no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Waiting from '../Waiting';
import FormComponentV2 from '../FormComponent/loginFormV2';
import { login, companyLogin } from '../../redux/actions';
import { loginPageFields } from '../../../mockData';
import ForgetPassword from '../ForgetPassword';
import { SsoLogin } from '../../redux/constants/apiConstants';
import { TimeZone } from '../../utils/constants';
import { fetchApi } from '../../utils/methods';
import { toast } from 'react-toastify';
import { ssoCompanyLogin } from '../../redux/actions/registerActions';

class LoginPageV2 extends React.Component {
  constructor() {
    super();
    this.state = {
      errors: false,
      showReset: false,
      isSubmitted: '',
      showLogin: true,
      buttonstatus:false
    }
  }

  showLoginorRegister = () => {
    const { history } = this.props;
    history.push("/register")
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { history, login, companyLogin } = this.props;
    const { target } = e;
    const schema = {};
    Object.keys(target).map((key) => {
      if (target[key]?.name) {
        schema[target[key]?.name] = target[key]?.value;
      } else if (target[key]?.type === 'submit') {
        switch (target[key]?.textContent) {
        case 'register': {
          companyLogin(schema, history, this.changeSubmit);
          break;
        }
        case 'login': {
          login(schema, history, this.changeSubmit);
          break;
        }
        case "S'inscrire": {
          companyLogin(schema, history, this.changeSubmit);
          break;
        }
        case 'Connexion': {
          login(schema, history, this.changeSubmit);
          break;
        } case 'Registrarse': {
          companyLogin(schema, history, this.changeSubmit);
          break;
        }
        case 'Acceso': {
          login(schema, history, this.changeSubmit);
          break;
        }
        case 'registro': {
          companyLogin(schema, history, this.changeSubmit);
          break;
        }
        case 'Conecte-se': {
          login(schema, history, this.changeSubmit);
          break;
        }
        }
      }
    });
  };

  changeSubmit = (value) => {
    this.setState({
      isSubmitted: value
    })
  };

  getErrors = (errors) => {
    this.setState({
      errors: errors
    });
  };

  showResetPassword = () => {
    this.setState({
      showReset: !this.state.showReset
    })
  };

  setSso = (bool)=>{
    this.setState({showLogin:bool})
  }

  onSsoSubmit = (e)=>{
    e.preventDefault();
    const { target } = e;
    const schema = {};
    Object.keys(target).map((key) => {
      if (target[key]?.name) {
        schema[target[key]?.name] = target[key]?.value;
      } 
    });

    const payload = {
      email : schema.emailOrUsername,
      time_zone:TimeZone
    }
    this.PostSsoData(payload)
  }

  PostSsoData = async(payload)=>{
    this.setState({buttonstatus:true})
    try{
      const res = await fetchApi(SsoLogin, "POST",payload);
      console.log("testing data",res);
      if(res?.data?.message){
        toast.error(res?.data?.message);
        this.setState({buttonstatus:false})
      }else{
        
        if(res.data.url){
          // this.props.ssoCompanyLogin(res.data.data,this.props.history, this.changeSubmit);
          
        }else{
          window.location.href = res.data[0];

        }
        // toast.success(res?.data?.[0]);
        // this.props.history.push("/company/surveys");
        // this.setState({buttonstatus:false});
      }
    }catch(error){
      toast.error(error)
      this.setState({buttonstatus:false});
    }
  }

  render() {
    const { isLoggingIn, errorMessage, companyLoggingIn, companyError, networkError, history } = this.props;
    const { showReset, isSubmitted, showLogin,buttonstatus } = this.state;
    return (
      <React.Fragment>
        {((isLoggingIn || companyLoggingIn) && !networkError) && <Waiting />}
        {showLogin ?
          <FormComponentV2
            showResetPassword={this.showResetPassword}
            section={loginPageFields[0]}
            onSubmit={(e) => this.onSubmit(e)}
            loginError={errorMessage}
            getErrors={this.getErrors}
            companyError={companyError}
            isSubmitted={isSubmitted}
            changeSubmit={this.changeSubmit}
            type="login"
            history={history}
            showLoginorRegister={this.showLoginorRegister}
            setSso={this.setSso}
          /> :
          <FormComponentV2
            showResetPassword={this.showResetPassword}
            section={loginPageFields[2]}
            onSubmit={(e) => this.onSsoSubmit(e)}
            companyError={companyError}
            getErrors={this.getErrors}
            loginError={errorMessage}
            isSubmitted={isSubmitted}
            changeSubmit={this.changeSubmit}
            type="sso"
            history={history}
            showLoginorRegister={this.showLoginorRegister}
            setSso={this.setSso}
            buttonStatus={buttonstatus}
          />
          //<FormComponentV2
          //showResetPassword={this.showResetPassword}
          //section={loginPageFields[1]}
          //onSubmit={(e) => this.onSubmit(e)}
          //companyError={companyError}
          //getErrors={this.getErrors}
          //loginError={errorMessage}
          //isSubmitted={isSubmitted}
          //changeSubmit={this.changeSubmit}
          //type="register"
          //history={history}
          //showLoginorRegister={this.showLoginorRegister}
        //> 
        }
        <ForgetPassword showModal={showReset} onClose={this.showResetPassword} history={history} />
      </React.Fragment>
    )
  }
}

LoginPageV2.propTypes = {
  history: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isLoggingIn: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,

  companyLogin: PropTypes.func.isRequired,
  companyLoginSuccess: PropTypes.bool.isRequired,
  companyLoggingIn: PropTypes.bool.isRequired,
  companyError: PropTypes.string,
  networkError: PropTypes.string,
  ssoCompanyLogin: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  errorMessage: state.auth.error,
  isLoggedIn: state.auth.isLoggedIn,
  isLoggingIn: state.auth.isLoggingIn,

  companyLoginSuccess: state.register.companyLoginSuccess,
  companyLoggingIn: state.register.companyLoggingIn,
  companyError: state.register.companyError,
  networkError: state.register.networkError
});

const mapDispatchToProps = (dispatch) => ({
  login: (data, history, changeSubmit) => dispatch(login(data, history, changeSubmit)),
  companyLogin: (data, history, changeSubmit) => dispatch(companyLogin(data, history, changeSubmit)),
  ssoCompanyLogin: (res,history, changeSubmit) => dispatch(ssoCompanyLogin(res,history, changeSubmit))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageV2);