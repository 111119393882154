import styled, { css } from 'styled-components';

const BoldFont = 'Rubik-Bold';

const StyledColumn = styled.div`
  text-align: center;
  margin-top: 8vmin;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #cdcbcb;
  height: 360px;
  padding: 30px;
  width: calc(46% - 20px);
  display: inline-block;
  float: left;
  margin-left: ${({marginLeft}) => marginLeft && '20px'};
  margin-right: ${({marginRight}) => marginRight && '20px'};
  
  &:last-child {
    float: right;
    margin-right: 20px;
    margin-left: 0;
  }
  
  > span {
    font-size: 18px;
    letter-spacing: 0px;
    color: rgb(51,51,51);
    font-family: ${BoldFont};
    text-transform: uppercase;
  }
  
  @media (max-width: 800px) {
    width: 80%;
    margin: 8vmin 10%;
    height: auto;
    &:last-child {
      float: left;
      width: 80%;
      margin: 8vmin 10%;
    }
  }
  
  @media (max-width: 500px) {
    width: 90%;
    margin: 8vmin 5%;
    &:last-child {
      width: 90%;
      margin: 8vmin 5%;
    }
  }
`;

const CustomInput = styled.input`
  width: 100%;
  padding: 15px 10px 15px 40px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #cdcbcb;
  font-size: 14px;
  letter-spacing: 0px;
  color: rgb(153,153,153);
  
  &:hover, &:focus, &:active {
    outline: none;
  }
  
  ::placeholder {
    color: rgb(153,153,153);
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: rgb(153,153,153);
  }

  ::-ms-input-placeholder {
    color: rgb(153,153,153);
  }
`;

const InputContainer = styled.div`
  margin: 20px 30px 30px 30px;
  position: relative;
    
  @media (max-width: 500px) {
    margin: 20px 0 30px;
  }
  .input-field-icon {
    position: absolute;
    left: 15px;
    line-height: 47px;
    font-size: 16px;
    color: rgb(153,153,153);
    top: 0
  }
  .eye-icon {
    position: absolute;
    right: 15px;
    line-height: 47px;
    font-size: 16px;
    color: rgb(153,153,153);
    top: 0;
    cursor: pointer;
  }
  > img {
    position: absolute;
    left: 15px;
    top: 14px;
  }

  > ul {
    position: absolute;
  }
`;

const Button = styled.button`
  background-color: ${({changeColor}) => changeColor ? '#ef6827' : '#159fc9'};
  border: ${({changeColor}) => changeColor ? '1px solid #ef6827' : '1px solid #159fc9'};
  color: white;
  white-space: nowrap;
  padding: 10px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 30px;
  text-transform: uppercase;
  font-family: ${BoldFont};
  width: 30%;
  
  @media (max-width: 800px) {
    width: auto;
    padding: 10px 20px;
  }
  
  &:focus, &:active, &:hover {
    outline: none;
  }
  
  ${({disabled}) => disabled && css`
    cursor: not-allowed !important;
    opacity: 0.5;
  `}
  
  @media (max-width: 800px) {
    padding: 10px 20px;
    width: auto;
  }
`;

const StyledLink = styled.a`
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 60px;
  color: rgb(21,159,202);
  cursor: pointer;
`;

const FormContainer = styled.div`
  float: left;
  width: 100%;
`;

const Text = styled.div`
  display: inline-block;
  line-height: 360px;
  margin-top: 8vmin;
  font-size: 16px;
  letter-spacing: 0px;
  color: rgb(51,51,51);
  font-family: ${BoldFont};
  text-align: center;
  text-transform: uppercase;
  width: 8%;
  float: left;
  
  @media (max-width: 800px) {
    display: block;
    text-align: center;
    float: left;
    width: 100%;
    margin-top: 0;
    line-height: unset;
  }
`;

const ErrorMessage = styled.li`
  text-align: left;
  margin-top: 5px;
  font-size: 12px;
  color: red;
`;

const StyledColumnV2 = styled.div`
  text-align: center;
  border-radius: 4px;
  background-color: #ffffff;
  height: 500px;
  width: 500px;
  display: inline-block;
  margin: auto;
  display: block;
  // margin-left: ${({marginLeft}) => marginLeft && '20px'};
  // margin-right: ${({marginRight}) => marginRight && '20px'};
  

  
  > span {
    font-size: 18px;
    letter-spacing: 0px;
    color: rgb(51,51,51);
    font-family: ${BoldFont};
    text-transform: uppercase;
  }
  
  @media (max-width: 800px) {
    width: 80%;
    margin: 8vmin 10%;
    height: auto;
    &:last-child {
      float: left;
      width: 80%;
      margin: 8vmin 10%;
    }
  }
  
  @media (max-width: 500px) {
    width: 90%;
    margin: 8vmin 5%;
    &:last-child {
      width: 90%;
      margin: 8vmin 5%;
    }
  }
`;

export { StyledColumn, CustomInput, InputContainer, Button, StyledLink, FormContainer, Text, ErrorMessage, StyledColumnV2 };
