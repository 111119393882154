/* eslint-disable no-console,no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Waiting from '../Waiting';
import FormComponent from '../FormComponent';

import { login, companyLogin } from '../../redux/actions';

import { Text } from './styles';

import { loginPageFields } from '../../../mockData';
import ForgetPassword from '../ForgetPassword';

class LoginPage extends React.Component {
  constructor() {
    super();
    this.state = {
      errors: false,
      showReset: false,
      isSubmitted: ''
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { history, login, companyLogin } = this.props;
    const { target } = e;
    const schema = {};
    Object.keys(target)?.map((key) => {
      if (target[key].name) {
        schema[target[key]?.name] = target[key]?.value;
      } else if (target[key]?.type === 'submit') {
        switch(target[key]?.textContent) {
        case 'register': {
          companyLogin(schema, history, this.changeSubmit);
          break;
        }
        case 'login': {
          login(schema, history, this.changeSubmit);
          break;
        }
        }
      }
    });
  };

  changeSubmit = (value) => {
    this.setState({
      isSubmitted: value
    })
  };

  getErrors = (errors) => {
    this.setState({
      errors: errors
    });
  };

  showResetPassword = () => {
    this.setState({
      showReset: !this.state.showReset
    })
  };

  render() {
    const { isLoggingIn, errorMessage, companyLoggingIn, companyError, networkError, history } = this.props;
    const { showReset, isSubmitted } = this.state;
    return (
      <div>
        {((isLoggingIn || companyLoggingIn) && !networkError) && <Waiting />}
        <FormComponent
          showResetPassword={this.showResetPassword}
          section={loginPageFields[0]}
          onSubmit={(e) => this.onSubmit(e)}
          loginError={errorMessage}
          getErrors={this.getErrors}
          companyError={companyError}
          isSubmitted={isSubmitted}
          changeSubmit={this.changeSubmit}
          type="login"
          history={history}
        />
        <Text>or</Text>
        <FormComponent
          showResetPassword={this.showResetPassword}
          section={loginPageFields[1]}
          onSubmit={(e) => this.onSubmit(e)}
          companyError={companyError}
          getErrors={this.getErrors}
          loginError={errorMessage}
          isSubmitted={isSubmitted}
          changeSubmit={this.changeSubmit}
          type="register"
          history={history}
        />
        <ForgetPassword showModal={showReset} onClose={this.showResetPassword} history={history}/>
      </div>
    )
  }
}

LoginPage.propTypes = {
  history: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isLoggingIn: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,

  companyLogin: PropTypes.func.isRequired,
  companyLoginSuccess: PropTypes.bool.isRequired,
  companyLoggingIn: PropTypes.bool.isRequired,
  companyError: PropTypes.string,
  networkError: PropTypes.string,
};

const mapStateToProps = (state) => ({
  errorMessage: state.auth.error,
  isLoggedIn: state.auth.isLoggedIn,
  isLoggingIn: state.auth.isLoggingIn,

  companyLoginSuccess: state.register.companyLoginSuccess,
  companyLoggingIn: state.register.companyLoggingIn,
  companyError: state.register.companyError,
  networkError: state.register.networkError
});

const mapDispatchToProps = (dispatch) => ({
  login: (data, history, changeSubmit) => dispatch(login(data, history, changeSubmit)),
  companyLogin: (data, history, changeSubmit) => dispatch(companyLogin(data, history, changeSubmit))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);